import { DiffEditor } from "@monaco-editor/react";
import { Typography } from "@mui/material";
import { CodeRunDetails } from "../../../types/CodeRunDetails";

interface CodeRunBodyCardsProps {
  codeRunDetails?: CodeRunDetails;
  olderCodeRunDetails?: CodeRunDetails 
}

const CodeRunBodyCards = ({
  codeRunDetails,
  olderCodeRunDetails
}: CodeRunBodyCardsProps) => {

  return (
    <>
      <Typography variant="h6">Code</Typography>
      <DiffEditor
        original={olderCodeRunDetails?.code}
        modified={codeRunDetails?.code}
        language="python"
        height={'40vh'}
        options={{ readOnly: true }}
      />
      </>
  );
};

export default CodeRunBodyCards;
